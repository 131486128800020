import React, { FC, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { MsgInformativo } from "../../Utils/Toast/msgInformativo";
import DTOUsuario from "../../DTO/DTOUsuario";
import logo from "../../images/grupo-promax-b.png";
import AuthDS from "../../Services/Auth/Auth.Service";
import { DTOResetPassword } from "../../DTO/DTOResetPassword";

interface IProps {}

export default function ResetCredentials(props: IProps) {
  const [User, setUser] = useState(() => {
    const stickyValue = window.localStorage.getItem("User");
    return stickyValue !== null ? JSON.parse(stickyValue) : "";
  });
  const [PIN, setPIN] = useState(() => {
    const stickyValue = window.localStorage.getItem("PIN");
    return stickyValue !== null ? JSON.parse(stickyValue) : "";
  });
  const [header, setHeader] = useState("Error!");
  const [msgColor, setMsgColor] = useState("primary");
  const [showMsg, setShowMsg] = useState(false);
  const [msg, setMsg] = useState("");
  const [Usuario, setUsuario] = useState(User);
  const [Contrasena, setContrasena] = useState("");
  const [ConfirmaContrasena, setConfirmaContrasena] = useState("");
  const [show, setShow] = useState(false);

  const validaFormulario = () => {
    if (Contrasena.length === 0 || ConfirmaContrasena.length === 0) {
      setShow(true);
      setMsg("La contraseña no puede estar vacia!");
      setHeader("Error");
      return;
    }
    if (Contrasena !== ConfirmaContrasena) {
      setShow(true);
      setMsg("Contraseña no coincide con la confirmacion!");
      setHeader("Error");
      return;
    }
    const data: DTOResetPassword = {
      pin: PIN,
      contrasena: Contrasena,
    };
    AuthDS.resetPassword(data)
      .then((response) => {
        if (response.status == 200) {
          setShow(true);
          setMsg("La contraseña se cambio exitosamente!");
          setHeader("Confirmacion");
          return false;
        }
      })
      .catch((e: Error) => {
        setShow(true);
        setMsg("Ocurrio un error");
        setHeader("Error");
        return false;
      });
  };

  const closeToast = (show: boolean): void => {
    setShowMsg(false);
  };

  return (
    <div>
      <div>
        <Row style={{ paddingTop: "6rem" }}>
          <Col xs={12}></Col>
        </Row>
        <Row>
          <Col xs={5}>&nbsp;</Col>
          <Col xs={2}>
            <div className="shadow-lg bg-white rounded">
              <Card style={{ width: "100%" }} className="Color1">
                <Card.Header>
                  <Row style={{ backgroundColor: "#ffffff" }}>
                    <Col xs={12}>
                      <img
                        src={logo}
                        alt=" Adminusa Internacional"
                        style={{ width: "70%" }}
                      ></img>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <Row
                      style={{
                        backgroundColor: "#572364",
                        color: "#ffffff",
                        fontSize: "19px",
                      }}
                    >
                      <Col xs={12}>Proporcione su nueva contraseña?</Col>
                    </Row>
                    <Row>
                      <Col xs={12}>&nbsp;</Col>
                    </Row>
                    <Form id="login-form" className="form">
                      <Row style={{ height: "0px" }}>
                        <Col>
                          <Form.Control
                            style={{ visibility: "hidden" }}
                            id="PIN"
                            className="form-control"
                            placeholder="PIN"
                            value={PIN}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <Form.Control
                            id="Contrasena"
                            className="form-control"
                            placeholder="Contraseña"
                            type="password"
                            value={Contrasena}
                            onChange={(e) => {
                              setContrasena(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>&nbsp;</Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <Form.Control
                            id="Contrasena"
                            className="form-control"
                            placeholder="Confirme contraseña"
                            type="password"
                            value={ConfirmaContrasena}
                            onChange={(e) => {
                              setConfirmaContrasena(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>&nbsp;</Col>
                      </Row>
                    </Form>
                  </Card.Text>
                  <div className="d-grid gap-2">
                    <Button
                      variant="customColor"
                      size="lg"
                      onClick={() => {
                        validaFormulario();
                      }}
                    >
                      reset
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col xs={5}>&nbsp;</Col>
        </Row>
        <MsgInformativo
          show={show}
          msg={msg}
          time={2000}
          header={header}
          msgColor={msgColor}
          closeToast={function (arg: boolean): void {
            setShow(false);
          }}
        />
      </div>
      {/*  <MsgInformativo
        show={showMsg}
        time={2000}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={closeToast}
      /> */}
    </div>
  );
}
