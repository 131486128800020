import DtoPrecuentaCheques from '../../Componest/Catalogos/CatRelacionBookPrecta/DTO/DTOPrecuentaCheques'
import { DTORelPreChe } from '../../DTO/DTORelPreChe'
import { DTOWorkingHours } from '../../DTO/DTOWorkingHours'
import { DTOTotalHours } from '../../DTO/Reportes/DTOTotalHours'
import { DTOClocks } from '../../DTO/Reportes/DTOClocks'
import http from '../common/http-common'


class reportsDataService {
    getRptRelacionPreChe(Precuenta : string,  Cheques: string )
    {
        return http.get<Array<DTORelPreChe>>(
            `/Catalogos/CatRelacionesBookPrecta/GetAllPreChe`
        )
    }


    getRptRelacionesTrafico()
    {
        return http.get<Array<DTORelPreChe>>(
            `/Reportes/GetAllSoloTrafico`

        )
    }


    getRptNoRelaciones()
    {
        return http.get<Array<DtoPrecuentaCheques>>(
            `/Reportes/GetAllNoRelaciones`
        )
    }



    getRptWorkingHours(Inicio : string , Fin: string )
    {
        return http.get<Array<DTOWorkingHours>> (
            `/Reportes/GetAllWorkingHours?Inicio=${Inicio}&Fin=${Fin}    `
        )
    }

    getRptTotalHours(Inicio : string , Fin : string)
    {
        return http.get<Array<DTOTotalHours>> (
        `/Reportes/TotalHours?Inicio=${Inicio}&Fin=${Fin}`)
    }

    


    PostTotalHours(data: DTOWorkingHours) {
        return http.post<Array<DTOWorkingHours>>
        ( "/Reportes/UpdateWorkingHours", data)
    }


}
export default new reportsDataService()