import * as React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import logo from "../../images/grupo-promax-b.png";
import { MsgInformativo } from "../../Utils/Toast/msgInformativo";
import ReactCaptchaa from "react-captchaa";
import AuthDS from "../../Services/Auth/Auth.Service";
import "react-captchaa/dist/index.css";
import "../../css/reactCaptcha.css";
import ILogin from "../../interfaces/Ilogin";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export interface IForgotPasswordProps {}

export default function ForgotPassword(props: IForgotPasswordProps) {
  const navigate = useNavigate();
  const [Usuario, setUsuario] = useState("");
  const [Contrasenia, setContrasenia] = useState("");
  const [Captcha, setCaptcha] = useState("");
  const [CaptchaText, setCaptchaText] = useState("");
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [header, setHeader] = useState("");
  const [msgColor, setMsgColor] = useState("primary");

  const validateCaptcha = () => {
    if (!Usuario.length) {
      setShow(true);
      setMsg("El campo usuario no puede estar vacio");
      setHeader("Error");
      return;
    }
    if (Captcha !== CaptchaText) {
      setShow(true);
      setMsg("Captcha o usuario incorrecto");
      setHeader("Error");
      return;
    }
    const data: ILogin = {
      Usuario: Usuario,
      Contrasena: Contrasenia,
    };
    AuthDS.forgotPassword(data)
      .then((response: any) => {
        localStorage.setItem("User", JSON.stringify(Usuario));
        navigate("/PIN");
      })
      .catch((e: AxiosError) => {
        setShow(true);
        setMsg("Usuario no existe");
        setHeader("Error");
      });
  };

  return (
    <div>
      <Row style={{ paddingTop: "6rem" }}>
        <Col xs={12}></Col>
      </Row>
      <Row>
        <Col xs={5}>&nbsp;</Col>
        <Col xs={2}>
          <div
            className="shadow-lg bg-white rounded"
            style={{ marginLeft: "auto", marginRight: "auto", width: "125%" }}
          >
            <Card className="Color1">
              <Card.Header>
                <Row style={{ backgroundColor: "#ffffff" }}>
                  <Col xs={12}>
                    <img
                      src={logo}
                      alt="Adminusa"
                      style={{ width: "70%" }}
                    ></img>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <Row>
                    <Col xs={12}>&nbsp;</Col>
                  </Row>
                  <Form id="login-form" className="form">
                    <Row>
                      <Col>
                        <Form.Control
                          id="Usuario"
                          className="form-control"
                          placeholder="Usuario"
                          value={Usuario}
                          onChange={(e) => {
                            setUsuario(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>&nbsp;</Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <ReactCaptchaa
                          captchaText={(captchaText: string) => {
                            setCaptchaText(captchaText);
                          }}
                          captchaLength={6}
                          captchaButtonClick={(e: any) => {}}
                          onCanvasClick={(e: any) => {}}
                          height={150}
                          width={200}
                          iconName={"FiRefreshCw"}
                          iconColor={"#fff"}
                          fontSize={"3em"}
                          iconSize={"1em"}
                          containerClassName={"react-captcha"}
                          iconWrapperClassName={"react-captcha-icon-wrapper"}
                          canvasClassName={"react-captcha-canvas"}
                          iconClassName={"react-captcha-icon"}
                          charactersInclude={
                            "0123456789abcdefghijklmnopqrstuvwxzABCDEFGHIJKLMNOPQRSTUVWXYZ"
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Form.Control
                          id="Captcha"
                          className="form-control"
                          placeholder="Teclee los caracteres de la imagen"
                          value={Captcha}
                          onChange={(e) => {
                            setCaptcha(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>&nbsp;</Col>
                    </Row>
                  </Form>
                </Card.Text>
                <div className="d-grid gap-1"></div>
                <div className="d-grid gap-1">
                  <Button
                    variant="customColor"
                    size="lg"
                    onClick={() => {
                      validateCaptcha();
                    }}
                  >
                    Continuar
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
        <Col xs={5}>&nbsp;</Col>
      </Row>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        time={2000}
        closeToast={function (arg: boolean): void {
          setShow(false);
        }}
      />
    </div>
  );
}
