import React, { useEffect, useState } from 'react'
import ItemMenu from './interfaces/Catalogos/IItemMenu'
import { Outlet, useNavigate } from 'react-router-dom'
import { logued } from './Store/features/userStatusSlice/userStatusSlice'
import {
  Badge,
  Button,
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap'
import usuariosServices from './Services/Catalogos/Usuarios.Services'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from './Store/store'
import { IconContext } from 'react-icons'
import { BsPersonCircle } from 'react-icons/bs'
import FService from './Componest/Cliente/Services/CatNotificaciones.Services'
import './App.css'
import ImportantImage from './images/fire-flame.gif'
import Submenu from './Componest/Submenu/Submenu'
import { populateCatNotificaciones } from './Store/features/Clientes/1868/CatNotificaciones'

function App() {
  const mCatNotificaciones = useSelector(
    (state: RootState) => state.CatNotificaciones.CatNotificaciones
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let _menu: ItemMenu[] = []
  const [mainMenu, setMainMenu] = useState(_menu)
  const userLogued = useSelector((state: RootState) => state.userStatus.value)
  const [show, setShow] = useState(false)
  const [msg, setMsg] = useState('')
  const [header, setHeader] = useState('')
  const msgColor = 'primary'
  const menu = localStorage.getItem('menu') ? localStorage.getItem('menu') : ''
  const User = () => {
    const stickyValue = window.localStorage.getItem('User')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  }
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null
      ? JSON.parse(stickyValue).substring(0, 4) + '.'
      : ''
  })

  useEffect(() => {
    if (menu && userLogued) {
      setMainMenu(JSON.parse(menu))
    } else {
      setMainMenu([])
    }
  }, [menu, userLogued])

  const loadCatNotificaciones = () => {
    FService.Get()
      .then((response) => {
        dispatch(populateCatNotificaciones(response.data))
      })
      .catch((e: Error) => {
        return
      })
  }

  useEffect(() => {
    navigate('/')
    loadCatNotificaciones()
  }, [])

  useEffect(() => {
    usuariosServices
      .validate()
      .then((response) => {
        if (response.status === 200) {
          dispatch(logued(true))
        }
      })
      .catch((e: Error) => {
        if (!window.location.href.indexOf('login')) {
          localStorage.setItem('menu', '')
          localStorage.setItem('token', '')
          setHeader('Informativo')
          setMsg('Su session ha expirado, necesita volver a loguerse')
          setShow(true)
          navigate('/')
        }
      })
  }, [dispatch, navigate])

  return (
    <div className='App'>
      <Navbar
        className='custom-theme'
        style={{ backgroundColor: '#572364' }}
        expand='lg'
      >
        <Container>
          <Navbar.Brand href='/' style={{ color: '#FFFFFF' }}>
            adminusa
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto'>
              {mainMenu
                ? mainMenu.map((itemMenu, index) => {
                    if (itemMenu.padreId === 0) {
                      return (
                        <>
                          <Submenu
                            descripcion={itemMenu.descripcion}
                            key={itemMenu.id}
                            allItems={mainMenu}
                            submenu={mainMenu.filter(function (item) {
                              return item.padreId === itemMenu.id
                            })}
                          />
                        </>
                      )
                    }
                  })
                : ''}
            </Nav>
            <Nav>
              <Form className='d-flex' style={{ color: '#ffffff' }}>
                {!userLogued ? (
                  <>
                    <Nav.Link style={{ color: '#ffffff' }} href='/#/login'>
                      Login
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    <>
                      <Nav.Link style={{ color: '#ffffff' }} href='/#/help'>
                        Help
                      </Nav.Link>
                      <Nav.Link style={{ color: '#ffffff' }} href='/#/logout'>
                        Logout
                      </Nav.Link>
                      <div
                        style={{ paddingTop: '5px', paddingLeft: '20px' }}
                      ></div>
                      <IconContext.Provider value={{ size: '25px' }}>
                        <BsPersonCircle />
                      </IconContext.Provider>
                      <div style={{ paddingTop: '5px', paddingLeft: '5px' }}>
                        {Depto}
                      </div>
                    </>
                    <div
                      style={{
                        paddingTop:'0',
                        position: 'relative',
                        visibility:
                          mCatNotificaciones.filter(
                            (notificacion) => notificacion.activo
                          ).length > 0
                            ? 'visible'
                            : 'hidden',
                      }}
                    >
                      <OverlayTrigger
                        trigger='click'
                        key={'bottom'}
                        placement={'left'}
                        overlay={
                          <Popover id={`popover-positioned-${'bottom'}`}>
                            <Popover.Header as='h3'>{`Adminusa les informa`}</Popover.Header>
                            <Popover.Body>
                              {mCatNotificaciones
                                ? mCatNotificaciones
                                    .filter(
                                      (notificacion) => notificacion.activo
                                    )
                                    .map((notificacion) => {
                                      return <div>{notificacion.mensaje}</div>
                                    })
                                : ''}
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <div className='center-circle'>
                          <div className='circle pulse'></div>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </>
                )}
              </Form>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </div>
  )
}

export default App
